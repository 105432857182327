// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-components-blog-blog-page-js": () => import("./../../src/components/blog/blog-page.js" /* webpackChunkName: "component---src-components-blog-blog-page-js" */),
  "component---src-components-blog-blog-post-js": () => import("./../../src/components/blog/blog-post.js" /* webpackChunkName: "component---src-components-blog-blog-post-js" */),
  "component---src-components-projects-project-post-js": () => import("./../../src/components/projects/project-post.js" /* webpackChunkName: "component---src-components-projects-project-post-js" */),
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-cv-js": () => import("./../../src/pages/cv.js" /* webpackChunkName: "component---src-pages-cv-js" */),
  "component---src-pages-gallery-js": () => import("./../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-news-list-js": () => import("./../../src/pages/news-list.js" /* webpackChunkName: "component---src-pages-news-list-js" */),
  "component---src-pages-projects-js": () => import("./../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-travel-js": () => import("./../../src/pages/travel.js" /* webpackChunkName: "component---src-pages-travel-js" */)
}

